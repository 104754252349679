
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Titillium+Web:wght@400;700&display=swap');
@import "block";
@import "variables";
@import "form";
@import "header";
@import "footer";
@import "page";
@import "~bootstrap/scss/bootstrap.scss";
@import '@splidejs/splide/css';
@import "~glightbox/dist/css/glightbox.css";
// Choices.js, plugin for select element
@import "~choices.js/public/assets/styles/base.min.css";
@import "~choices.js/public/assets/styles/choices.min.css";

table{
    border:none;
    tr{
        border-bottom: 1px solid #00AF8C;
        &:nth-child(even){
            background-color: #EFEFEF;
        }
        td{
            padding: 0px 10px;
            height: 60px!important;
        }
    }
    &.table-green{
        tr {
            border-bottom:none;
            &:first-child{
                border-top: 4px solid $green!important;
                border-bottom: 4px solid $green!important;
                background-color: transparent!important;
            }
            &:last-child{
                border-bottom: 4px solid $green!important;
            }
            &:nth-child(odd){
                background-color: #d0eae2;
            }
        }
    }
    &.table-orange{
        
        tr {
            border-bottom:none;
            &:first-child{
                border-top: 4px solid #b68f34!important;
                border-bottom: 4px solid #b68f34!important;
                background-color: transparent!important;
            }
            &:last-child{
                border-bottom: 4px solid #b68f34!important;
            }
            &:nth-child(odd){
                background-color: #ece1cb;
            }
        }
    }
}

.vw50{
    width: 50vw;
}
.carousel{
    padding-top: 200px;
    @include media-breakpoint-down(xxl){
        padding-top: 125px;
    }
}
.product-card .img-wrapper{
    position: relative;
    &::before{
        content:"";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $green;
        z-index: 1;
    }
    &::after{
        content:"View More";
        display: block;
        color: #1A8077;
        border-radius: 5px;
        background-color: $white;
        font-size: 17px;
        font-family: $font-secondary;
        padding: 9px 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%)translateY(-50%);
        z-index: 2;
    }
    &::before, &::after{
        opacity: 0;
        transition: opacity .3s ease;
    }
    &:hover{
        &::before, &::after{
            opacity: 1;
        }
    }
}
.font-secondary{
    font-family: $font-secondary;
}

.heading-font{
    font-family: $heading-font;
}


.btn{ 
    padding: .5rem 2rem;
    transition: all .75s ease-in-out;
}

.btn-primary{
    --bs-btn-hover-bg: #1A7F77;
    --bs-btn-hover-border-color: #1A7F77;
    --bs-btn-hover-color:white;
}
.btn-white{
    --bs-btn-hover-color:#1A7F77;
}
.quote{
    font-size: 50px;
    font-family: $font-secondary;
    color: $light-green;
}
blockquote{
    line-height: 1;
}

a{
    &.text-primary, &.text-secondary, &.text-green, &.text-black, &.text-white, &.text-orange {
        text-decoration: underline;
    }
}

.cta-footer{
    a{
        background-color: rgba(5, 72, 67, .7);
        aspect-ratio: 320/544;
        display: block;
        flex-grow: 1;
        width: 100%;
        @include media-breakpoint-down(lg){
            aspect-ratio: 21/9;
           ;
        }
        h2{
            z-index: 3;
            font-weight: 600;
            text-align: center;
            transform: translateY(-80px);
        }
     
        img{
            mix-blend-mode: multiply;
        }

        &::after{
            content:"";
            background-color: #1A7F77;
            z-index: 2;
            opacity: 0;
            transition: opacity .3s ease;
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &.slim{
            position: relative;
            &::after{
                display: none;
                
            }
            &::before{
                content: "";
                top:50%;
                left:100%;
                position: absolute;
                display: block;
                z-index: 1;
                transform: translateY(-50%);
                height: 35px;
                border-right: 1px solid $green;
                
            }
            background-color: #1A7F77!important;
            aspect-ratio: initial!important;
            transition: background-color .3s ease;
            .title{
                transition: color .3s ease;
                text-align: center;
            }
            &:hover{
                background-color: $green!important;
                .title{
                    color: $white!important;
                }
                &::before{
                    opacity: 0;
                }
            }
        }
        &:hover{
            &::after{
               opacity: 1; 
            }
        }
    }


}
.site-links{
    svg{
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
}
.container-top{
    padding-top: clamp(12vw, 125px, 200px);
 
    
    &.with-graphics{
        aspect-ratio: 96 / 125;
     
     
    }
}
.container-top-img-wrapper{
    background-color: rgba(87, 89, 91, .7);
    img{
        mix-blend-mode: multiply;
    }
}
.category-list{
    a{
        transition: color .3s ease;
        &:hover, &.active{
            background-color: $green!important;
            color:$white!important;
        }  
    }
}
.vertical-line{
    border-left: 1px solid $orange;
    flex-grow: 1;
    transform: translateX(80px)translateY(80px);
    pointer-events: none;
    
}
.bg-offwhite{
    background-color: #EDEDED;
}
.accordion-button::after{
    display: none;
}
#form-filter{
    button{
        img{
            height: 20px;
            width: 20px;
        }
    }
}
.btn-white.border-green{
    color: $green;
    &:hover{
        background-color: $green;
        border-color: $green;
        color: $white;
    }
}
.btn-white.border-light-green{
    color: #1A7F77;
    border-color: #1A7F77;
    &:hover{
        background-color: #1A7F77;
        border-color: #1A7F77;
        color: $white;
    }
}


.btn-secondary{
    --bs-btn-hover-bg:var(--bs-green);
    --bs-btn-hover-border-color:var(--bs-green);
    --bs-btn-hover-color:white;
   
}

.content-list-wrapper{
    & > div{
        position: relative;
        overflow: hidden;
        .rectangle{
    
            position: absolute;
            pointer-events: none;
            left:0;
        }

        &:nth-child(odd){
            background-color: #F5F5F5;
            .rectangle{
    
                width: auto;
                height: 55%;
            }
        }
        &:nth-child(even){
            .rectangle{

                width: auto;
                height: 90%;
               
            }
        }
    }
}

.pagination{
    --bs-pagination-bg:transparent;
    --bs-pagination-border-color:transparent;
    --bs-pagination-color:var(--bs-green);
    --bs-pagination-hover-color:var(--bs-light-green);
    --bs-pagination-active-bg:transparent;
    --bs-pagination-active-color:var(--bs-light-green);
    --bs-pagination-active-border-color: transparent;
}
.page-item{
    font-weight: 500;
    .page-link{
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.page-item.disabled{
    display: none;
}
.page-item.active{
    .page-link{
        border-bottom: 1px solid $light-green;
        width: auto;
        padding: 0 5px;
    }

}
.pager-arrow-a{
    border:none!important;
    border-radius: 0!important;
}
.arrow-prev .pager-arrow-icon{
    background:none;
    content: url('../assets/img/left.svg');
}

.arrow-next .pager-arrow-icon{
    background:none;
    content: url('../assets/img/right.svg');
}

#info-pack-modal {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // display: none;
    background-color: rgba(0, 0, 0, .5);
    .recaptcha-notice{
        color:$white;
    }
}